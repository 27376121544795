import React, { useEffect } from 'react'
import { LazyComponent } from '../../components/LazyComponent'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'

const LandingPage = () => {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE

    if (gtmId) {
      const tagManagerArgs = { gtmId }
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Stay Updated with South Africa’s Latest Fuel Prices | SA Fuel Price</title>
        <meta name="description" content={'Get the most accurate and up-to-date fuel prices in South Africa.' +
        ' SA Fuel Price provides you with the latest petrol and diesel rates. Available on Andriod and Apple.'} />
      </Helmet>
      <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        <LazyComponent componentGetter={() => import('./_components/landingSection')}/>
        <LazyComponent componentGetter={() => import('./_components/aboutSection')}/>
        <LazyComponent componentGetter={() => import('./_components/featuresSection')}/>
        <LazyComponent componentGetter={() => import('../../components/footer/footer')}/>
      </div>
    </>
  )
}

export default LandingPage
